<template>
  <div>
    <b-form
      :key="formKey"
      class="auth-register-form"
      @submit.prevent="submit"
    >
      <b-form-group label="Выберите роль">
        <b-button-group
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.role_will === 2 ? 'primary' : 'outline-primary'"
            @click="setRole(2)"
          >
            Родитель
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="data.role_will === 1 ? 'primary' : 'outline-primary'"
            @click="setRole(1)"
          >
            Учитель
          </b-button>
        </b-button-group>
      </b-form-group>

      <template v-if="data.role_will === 2">
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group label="Ваше имя">
              <b-form-input
                key="parent_name-input"
                v-model="data.parent_name"
                v-validate="'required'"
                name="parent_name"
                data-vv-as="Имя"
                :state="errors.first('parent_name') ? false : null"
                placeholder="Введите имя"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors.first('parent_name') }}</small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group label="Имя ребёнка">
              <b-form-input
                key="child_name-input"
                v-model="data.name"
                v-validate="'required'"
                name="child_name"
                data-vv-as="Имя ребенка"
                :state="errors.first('child_name') ? false : null"
                placeholder="Введите имя ребёнка"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors.first('child_name') }}</small>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-else>
        <b-row>
          <b-col
            cols="12"
            lg="6"
            xl="4"
          >
            <b-form-group label="Фамилия">
              <b-form-input
                key="surname-input"
                v-model="data.surname"
                v-validate="'required'"
                name="surname"
                data-vv-as="Фамилия"
                :state="errors.first('surname') ? false : null"
                placeholder="Введите фамилию"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors.first('surname') }}</small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
            xl="4"
          >
            <b-form-group label="Имя">
              <b-form-input
                key="name-input"
                v-model="data.name"
                v-validate="'required'"
                name="name"
                data-vv-as="Имя"
                :state="errors.first('name') ? false : null"
                placeholder="Введите имя"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors.first('name') }}</small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            lg="6"
            xl="4"
          >
            <b-form-group label="Отчество">
              <b-form-input
                key="patronymic-input"
                v-model="data.patronymic"
                v-validate="'required'"
                name="patronymic"
                data-vv-as="Отчество"
                :state="errors.first('patronymic') ? false : null"
                placeholder="Введите отчество"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors.first('patronymic') }}</small>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group label="Ваш телефон">
            <b-input-group>
              <VuePhoneNumberInput
                v-model="data.phone"
                v-validate="'phone-is-correct'"
                data-vv-validate-on="blur"
                class="w-100"
                color="#7367f0"
                :dark="$store.state.appConfig.layout.skin === 'dark'"
                valid-color="#28A745"
                error-color="#DC3545"
                no-example
                :translations="{
                  countrySelectorLabel: 'Страна',
                  countrySelectorError: 'Ошибка',
                  phoneNumberLabel: 'введите номер телефона',
                  example: 'Пример :'
                }"
                :only-countries="['RU', 'BY', 'KZ', 'AM']"
                name="phone"
                @update="phoneUpdated"
              />
            </b-input-group>
            <small class="text-danger">
              {{ errors.first('phone') }}
            </small>
          </b-form-group>
        </b-col>
        <b-col
          v-if="data.role_will === 2"
          cols="12"
          lg="6"
        >
          <b-form-group label="В каком классе учится ребёнок">
            <b-form-input
              key="group-input"
              v-model="data.group"
              v-validate="'required'"
              name="group"
              data-vv-as="Класс"
              :state="errors.first('group') ? false : null"
              placeholder="Укажите класс"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors.first('group') }}</small>
          </b-form-group>
        </b-col>
        <b-col
          cols="7"
          class=" mt-2 "
        >
          <b-form-group>
            <b-form-checkbox
              id="register-privacy-policy"
              key="privacy-input"
              v-model="data.privacy"
              v-validate="'required'"
              name="privacy"
              :state="errors.first('privacy') ? false : null"
              data-vv-as="Соглашение и политика конфиденциальности"
            >
              {{ $t('page.register.privacy') }}
              <b-link
                href="https://rupetitor.ru/agreement.html"
                target="_blank"
              >
                {{ $t('page.register.privacy_link') }}
              </b-link>
              {{ $t('page.register.and') }}
              <b-link
                href="https://rupetitor.ru/privacy.html"
                target="_blank"
              >{{ $t('page.register.agreement_link') }}</b-link>
            </b-form-checkbox>
            <small class="text-danger">{{ errors.first('privacy') }}</small>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BInputGroup, BFormInput, BFormCheckbox, BLink, BForm,
  BButton, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'

export default {
  name: 'PersonalInfo',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BLink,
    BForm,
    BButton,
    BButtonGroup,
    BInputGroup,
    VuePhoneNumberInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        role_will: 2,
        name: null,
        surname: null,
        patronymic: null,
        parent_name: null,
        group: null,
        privacy: null,
        phone: null,
      },
      formKey: 0,
      phonePayload: null,
    }
  },
  computed: {
    tabByUrl() {
      const { role } = this.$route.query
      if (role === 'teacher') return 1
      return 2
    },
    dict() {
      return {
        custom: {
          phone: {
            required: 'Необходимо указать телефон',
            'phone-is-correct': 'Телефон введен некорректно',
          },
          surname: {
            required: 'Необходимо указать фамилию',
          },
          patronymic: {
            required: 'Необходимо указать отчество',
          },
          parent_name: {
            required: 'Необходимо указать ваше имя',
          },
          child_name: {
            required: 'Необходимо указать имя ребёнка',
          },
          name: {
            required: 'Необходимо указать имя',
          },
          group: {
            required: 'Необходимо указать класс',
          },
          privacy: {
            required: 'Необходимо принять пользовательское соглашение и политику конфиденциальности платформы',
          },
        },
      }
    },
    phoneIsValid() {
      if (!this.phonePayload) return false
      return this.phonePayload.isValid
    },
  },
  mounted() {
    this.$validator.extend('phone-is-correct', () => ({
      valid: this.phoneIsValid,
      data: {
        required: true,
      },
    }),
    {
      computesRequired: true,
    })
    this.$validator.localize('ru', this.dict)
    this.data.role_will = this.tabByUrl
  },
  methods: {
    phoneUpdated(payload) {
      this.phonePayload = payload
    },
    setRole(role) {
      this.$validator.pause()
      this.data = {
        ...this.data,
        name: null,
        surname: null,
        patronymic: null,
        parent_name: null,
        group: null,
        privacy: null,
      }
      setTimeout(() => {
        this.data.role_will = role
        this.$validator.resume()
      })
    },
    async submit() {
      const data = await this.$validator.validate().then(valid => {
        if (valid) {
          return { ...this.data, phone: this.phonePayload.formattedNumber.replace(/\D/g, '') }
        }
        return null
      })
      return data
    },
  },
}
</script>
