<template>
  <div class="auth-wrapper auth-v2">
    <b-row
      class="auth-inner m-0"
      no-gutters
    >

      <!-- Brand logo-->
      <div class="brand-logo">
        <vuexy-logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="3"
        class="d-none d-lg-flex align-items-center"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="9"
        class="d-flex align-items-center auth-bg p-lg-5"
      >
        <b-col
          sm="12"
          xl="8"
          class="mt-5 pt-3 mt-lg-0 pt-lg-0 mx-auto register-form-container"
        >
          <div class="px-2">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              <span>{{ $t('page.register.header') }}</span>
              <b-spinner
                v-if="loadingWizard"
                class="ml-1"
                type="grow"
                variant="primary"
              />
            </b-card-title>
            <b-card-text
              v-if="false"
              class="mb-2"
            >
              {{ $t('page.register.subheader') }}
            </b-card-text>
          </div>

          <!-- form -->
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            finish-button-text="Завершить регистрацию"
            back-button-text="Назад"
            next-button-text="Далее"
            class="steps-transparent"
            @on-complete="registerCompleted"
            @on-loading="setLoading"
          >
            <!-- personal details -->
            <tab-content
              title="Личная информация"
              icon="feather icon-user"
              :before-change="submitPersonalData"
            >
              <personal-info ref="personalInfo" />
            </tab-content>

            <!-- phone check -->
            <tab-content
              title="Подтверждение номера"
              icon="feather icon-phone"
              :before-change="phoneConfirmed"
            >
              <phone-check
                ref="phone"
                self-call
                :phone="registerData.phone"
                :code-approved="codeApproved"
                :code-wrong="codeWrong"
                :code-approving="codeApproving"
                :code-requesting="codeRequesting"
                :phone-is-taken="phoneIsTaken"
                :check-executed="checkExecuted"
                :error-message="codeApprovingErrorMessage"
                @codeRequested="getConfirmationCode"
                @smsCodeRequested="getConfirmationSmsCode"
                @checkCode="checkCode"
                @finish="phoneConfirmed"
                @onCodeApproved="phoneApproved"
                @onCodeError="phoneApprovingError"
              />
            </tab-content>

            <!-- password confirmation -->
            <tab-content
              title="Пароль"
              icon="feather icon-lock"
              :before-change="passwordConfirmed"
            >
              <password-check ref="password" />
            </tab-content>

          </form-wizard>

          <p class="text-center mt-2">
            <span>{{ $t('page.register.login_hint') }}</span>
            <b-link :to="{ name:'auth.login' }">
              <span>&nbsp;{{ $t('page.login.title') }}</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BImg, BCardTitle, BCardText, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapActions } from 'vuex'
import store from '@/store/index'
import PasswordCheck from '@/views/auth/register/PasswordCheck.vue'
import PhoneCheck from '@/views/auth/register/PhoneCheck.vue'
import PersonalInfo from '@/views/auth/register/PersonalInfo.vue'

export default {
  name: 'Register',
  components: {
    PersonalInfo,
    PhoneCheck,
    PasswordCheck,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    FormWizard,
    TabContent,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      registerData: {
        phone: null,
        password: null,
        password_repeat: null,
      },
      registeredUser: null,
      codeWrong: false,
      codeApproved: false,
      codeApproving: false,
      codeRequesting: false,
      checkExecuted: false,
      phoneIsTaken: false,
      tempAccessToken: null,
      loadingWizard: false,
      codeApprovingErrorMessage: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions({
      setUser: 'user/setUser',
    }),
    setLoading(value) {
      this.loadingWizard = value
    },
    resetPhoneData() {
      this.codeWrong = false
      this.codeApproved = false
      this.codeApproving = false
      this.codeRequesting = false
      this.checkExecuted = false
      this.phoneIsTaken = false
    },
    async submitPersonalData() {
      const data = await this.$refs.personalInfo.submit()
      if (data) {
        this.registerData = { ...data }
        return true

        // const { id } = this.registerData
        // if (id) {
        //   const updatedData = await this.$http.post('/auth/update-user', { id, ...data, role_will: data.role_will.toString() })
        //   if (!updatedData?.phone_confirmed) {
        //     this.resetPhoneData()
        //     this.$refs.phone.dropCode()
        //     this.registerData = { ...this.registerData, phone: data.phone }
        //   }
        //   return true
        // }
        // this.registerData = { ...data }
        // const registerResponse = await this.$http.post('/auth/registration', this.registerData)
        //   .then(response => {
        //     this.registerData.id = response.id
        //     return true
        //   })
        //   .catch(() => false)
        //
        // return registerResponse
      }
      return false
    },
    async getConfirmationCode() {
      const { phone } = this.registerData
      this.codeRequesting = true
      // await this.$chatsHttp.post('/leads/check-phone', { phone })
      await this.$http.post('/auth/registration-send-code', { phone })
        .then(() => {
          this.checkExecuted = true
        })
        .catch(() => {
          this.phoneIsTaken = true
        })
      this.codeRequesting = false
    },
    async getConfirmationSmsCode() {
      const { phone } = this.registerData
      this.codeRequesting = true
      await this.$http.post('/auth/registration-send-sms', { phone })
        .then(() => {
          this.checkExecuted = true
        })
        .catch(() => {
          this.phoneIsTaken = true
        })
      this.codeRequesting = false
    },
    async checkCode(code) {
      this.codeWrong = false
      this.codeApproving = true

      const { phone } = this.registerData
      const codeCheckSuccess = await this.$http.post('/auth/registration-check-code', { phone, code })
        .then(response => {
          this.codeApproving = false
          this.phoneApproved()
          const { accessToken } = response
          this.accessToken = accessToken
          return true
        })
        .catch(() => {
          this.codeWrong = true
          this.codeApproving = false
          return false
        })

      return codeCheckSuccess
    },
    phoneApproved() {
      this.codeWrong = false
      this.codeApproved = true
      this.checkExecuted = false
      this.codeApprovingErrorMessage = null
    },
    phoneApprovingError(message) {
      this.codeApproving = false
      this.checkExecuted = true
      this.codeWrong = true
      this.codeApprovingErrorMessage = message
    },
    async phoneConfirmed() {
      if (this.phoneIsTaken) {
        this.$router.push({ name: 'auth.login' })
        return false
      }
      return this.$refs.phone.codeApproved
    },
    async passwordConfirmed() {
      const data = await this.$refs.password.submit()
      if (!data) return false

      this.registerData.password = data.password
      this.registerData.password_repeat = data.password_repeat

      const regSuccess = await this.$http.post('/auth/registration-websocket-notification?expand=usersTeachers,usersStudent,image', this.registerData)
        .then(async response => {
          await this.setUser(response)
          return true
        })
        .catch(() => false)

      // const passSuccess = await this.$http.post('/auth/registration-set-password?expand=usersTeachers,usersStudent,image', {
      //   password: data.password,
      //   password_repeat: data.password_repeat,
      // }, {
      //   headers: {
      //     Authorization: `Bearer ${this.accessToken}`,
      //   },
      // })
      //   .then(async response => {
      //     await this.setUser(response)
      //     return true
      //   })
      //   .catch(() => false)

      return regSuccess
    },
    registerCompleted() {
      this.$router.push({ name: 'cabinet.dashboard' })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss">
.vue-form-wizard .wizard-card-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: none!important;
}
.vue-form-wizard.steps-transparent .wizard-tab-content {
  box-shadow: none!important;
}
@media (max-width: 1400px) {
  .register-form-container {
    &.col-xl-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
